


















































.theme--light.global-banner {
  border-top: 1px solid rgb(255 255 255 / 60%);
  .header-2 {
    font-size: 20px !important;
    letter-spacing: 0.012em !important;
  }
}
